.wrapper {
  width: 100%;
  height: 100%;
  background-color: #15151C;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
  background-color: #25252C;
  color: #DDDDD7;

  &__sp {
    @media screen and (min-width: 513px) {
      display: none;
    }
  }
}

.elements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  &__icon {
    width: 170px;
    height: 170px;
    border-radius: 100%;
  }

  &__name {
    @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0 1em;
    line-height: 170px;
    font-size: 4rem;
  }
}

.footer {
  background-color: #25252C;
  color: #DDDDD7;
  text-align: center;
  padding: 3em 0;
}


.triangle {
  $border_style: 3px #DDDDD7 solid;
  margin-top: 10rem;
  height: 70px;
  width: 70px;
  border-bottom: $border_style;
  border-right: $border_style;
  transform: rotate(45deg);
}
