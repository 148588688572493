.main-view {
  color: white;
  width: 90%;
  max-width: 1500px;
  min-width: 300px;
  margin: auto;
}


.profile {
  .row {
    display: grid;
    font-size: 2em;
    grid-template-columns: 7em 1fr;
    margin: 1.5rem 0;

    .small {
      font-size: 0.8em;
    }

    &__en {
      transform: scale(0.7);
      transform-origin: top left;
      color: #d0d0d0;
    }

    @media screen and (max-width: 768px) {
      display: block;

      & > div:not(.row__title) {
        padding-left: 1em;
      }
    }
  }
}

.skill-contents {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.other-skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 -1rem;
  flex-wrap: wrap;
}

.other-skill {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  background-color: #f3f0f0;
  padding: 1.5rem 3rem;
  margin: 1.5rem 1rem;
  color: black;
  border-radius: 2rem;
  flex-wrap: wrap;

  &__image {
    max-height: 150px;
  }

  &__text {
    margin: 0 0.4em;
    font-size: 3.6rem;
  }
}

.devices {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.contact-email,
.contact-twitter {
  display: inline-flex;
  align-items: center;
  background-color: #eeeeee;
  padding: 1em;
  border-radius: 10px;
  text-decoration: none;
  margin: 0.5rem 1rem;
  flex-wrap: wrap;
  border-bottom: solid 4px #666666;

  &__icon {
    margin: 0 1em;
    width: 60px;
  }

  &__address {
    color: #000000;
    font-size: 1.7rem;
    margin: 0 1em;
  }

  &:active {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
    border-bottom: none;
    border-top: solid 4px transparent;
  }
}

.experience {
  font-size: 24px;
  line-height: 1.4;
  padding-bottom: 16px;
}

.life {
  &__wrap {
    background: #404040;
    padding: 14px 0;
    font-size: 18px;
  }

  &__year {
    display: inline-block;
    background: #9090f0;
    font-size: 20px;
    padding: 8px 16px 8px 32px;
    margin-bottom: 12px;
  }


  .life-content {
    &__wrap {
      position: relative;
      margin: 0 16px 16px 60px;
      display: flex;
      background: white;
      padding: 0.8em;
    }

    &__date {
      position: absolute;
      display: flex;
      width: 110px;
      height: 1.7em;
      background: #ff6c00;
      font-size: 1.1em;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      font-weight: bold;
    }

    p {
      margin: unset;
      padding-left: 110px;
      color: black;


      @media screen and (max-width: 768px)  {
        padding-left: 0;
        padding-top: 1.8em;
      }
    }
  }
}
